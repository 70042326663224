.ProjectsFilters {
  margin-bottom: 1rem;
}

.ProjectsFilters .input,
.ProjectsFilters .searchInput,
.ProjectsFilters .installerInput,
.ProjectsFilters .projectTypeInput,
.ProjectsFilters .button {
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
}

.ProjectsFilters .searchInput {
  width: 251px;
}

.ProjectsFilters .installerInput,
.ProjectsFilters .projectTypeInput {
  width: 224px;
}

.ProjectsFilters .recordsPerPageInput {
  width: 65px;
  margin-right: 0.5rem;
}
