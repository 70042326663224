.MachineSettings {
  height: 100%;
}

.MachineSettings .inputNumber {
  width: 100%;
  margin-bottom: 10px;
}

.MachineSettings .airLeakLabelCol {
  width: fit-content;
  margin-right: 15px;
}

.MachineSettings .tableRowsNamesCol {
  width: fit-content;
  margin-right: 5px;
}

.MachineSettings .formItem {
  margin-bottom: 0;
}

.errorMessage {
  color: red;
}

.airLeakErrorMessage {
  margin-left: 10px;
  color: red;
  width: 230px;
}

.section {
  margin-bottom: 30px;
}

.row {
  padding: 5px 10px 10px 10px;
  border-radius: 8px;
}

.narrowRow {
  padding: 0 10px 0 10px;
  border-radius: 8px;
}

.buttonRow {
  width: 97%;
  margin-bottom: 15px;
}

.col:global.ant-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0px;
}

.col:global.ant-col:not(:last-of-type) {
  margin-bottom: 10px;
  margin: 0 20px;
}

.formlabel {
  max-width: 440px;
}

.columnLabel {
  height: 25px;
  width: 100%;
  color: #292929;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 20px;
}

.formItemTable:global.ant-form-item {
  margin: 0px 2px;
}

.formItem:global.ant-form-item {
  margin: -5px 1px 0 -2px;
  height: 49px;
}

.field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formButton {
  margin-left: 10px;
}

.fieldTitle {
  position: relative;
  top: 5px;
}

.checkboxPanel {
  margin: 20px 0;
}

.project_info_block {
  margin-left: 5px;
}
.project_info {
  position: relative;
  left: 70px;
}