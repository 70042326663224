.SiteFooter {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  background-color: #132b78;
}

.SiteFooter .linksBlock {
  margin-right: 30px;
  font-size: 11px;
  letter-spacing: 0.40px;
  line-height: 16px;
}

.SiteFooter .linksBlock a {
  color: #ffffff;
}
.SiteFooter .linksBlock a:hover {
  color: #ffd479;
}

.SiteFooter .languageSelect {
  margin-left: auto;
}
