.MachineSettingsPage {
  min-width: 1000px;
  background-color: var(--white);
  border-top: 1px solid #d9d9d9;
}

.MachineSettingsPage .pageRow {
  margin-top: 20px;
}

@media print {
  /* @page { size: landscape } */
  * {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
}
