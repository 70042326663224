:root {
  --white: #fff;
  --darkBlue: #0081B7;
  --accent: #00AED9;
  --disabled: #CBDADC;
  --red: #DA5B15;
  --green: #40C97A;

  --lines: #DCE5E7;
  --text: #58585A;
  --textInFields: #242428;

  --fontSize: 16px;
  --fontSizeBigger: 18px;

  --padding: 5px;
  --paddingBigger: 8px;
}