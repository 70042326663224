.LoginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 384px;
}

.LoginForm .formCard {
  padding: 25px;
  background: rgba(216, 216, 216, 0.5);
  border-radius: 8px;
}

.LoginForm .formField {
  margin-bottom: 10px;
}

.LoginForm .formInput {
  height: 40px;
}

.LoginForm .forgotLink {
  text-decoration: underline;
}
