.OptionsForm {
}

.OptionsForm .input {
  width: 280px;
  margin-bottom: 10px;
}

.OptionsForm .button {
  display: block;
  margin-bottom: 16px;
}
