.OptionsPage {
  height: 100%;
  background-color: var(--white);
  border-top: 1px solid #d9d9d9;
}

.OptionsPage .pageRow {
  margin-top: 20px;
}

.OptionsPage .title {
  margin-bottom: 40px;
  font-weight: 450;
  font-size: large;
}
