.HomePage {
  height: 100%;
}

.HomePage .pageRow {
  height: 85%;
}

.homepageMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 168px;
  width: 472px;
  border-radius: 4px;
  background-color: rgba(216, 216, 216, 0.5);
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 128px;
  width: 109px;
  border-radius: 4px;
  background-color: #132b76;
  margin: 0px 20px 0 20px;
  cursor: pointer;
}

.itemImage {
  margin: 0 auto;
  margin-bottom: 15px;
  height: 59px;
  width: 51px;
}

.itemText {
  margin: 0 auto;
  height: 28px;
  width: 94px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 14px;
  text-align: center;
}
