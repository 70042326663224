@font-face {
 font-family: "PT Sans";
 src: url("./../fonts/PTSans-Regular.ttf") format("truetype"),
      url("./../fonts/PTSans-Bold.ttf") format("truetype"),
}

@font-face {
 font-family: "Open Sans";
 src: url("./../fonts/OpenSans-Regular.ttf") format("truetype"),
      url("./../fonts/OpenSans-SemiBold.ttf") format("truetype"),
}

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('../../assets/image/bg.png') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
* Ant Design customizations
**/

/* Layout */
.ant-layout,
.ant-layout-header,
.ant-layout-footer {
  background: none;
}

.ant-layout-header {
  margin-bottom: 30px;
}

.ant-layout-content {
  min-height: auto;
  margin-bottom: 30px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

/* Pagination */
.ant-pagination.ant-table-pagination {
  display: flex;
  justify-content: center;
  float: none;
}

.ant-pagination-item-active {
  background: #1890ff;
}

.ant-pagination-item.ant-pagination-item-active a {
  color: var(--white);
}

/* Titles */
.ant-table-column-title, .ant-modal-title {
  font-family: 'Open Sans';
  font-weight: 600;
}

/* Inputs */
.ant-form-extra {
  font-size: 12px;
}

/* Tooltips */
.ant-tooltip .ant-tooltip-inner {
  color: var(--text);
  background: var(--white);
}

.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  background-color: var(--white) !important;
}
