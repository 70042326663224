.NewProjectForm {
}

.NewProjectForm .sectionTitle {
  margin-bottom: 10px;
}

.NewProjectForm .title {
  font-size: large;
  font-weight: 450;
}

.NewProjectForm .generalInput,
.NewProjectForm .clientInput {
  width: 300px;
  margin-right: 35px;
}

.NewProjectForm .clientInputNo {
  width: 150px;
}
