.ProjectDetails {
  margin-top: 50px;
  background-color: var(--white);
}

.ProjectDetails .title {
  font-size: large;
  font-weight: 450;
}

.ProjectDetails .firstRow {
  min-height: 70px;
}

.ProjectDetails .lastRow {
  margin-bottom: 15px;
}

.ProjectDetails .addButton {
  margin-left: 15px;
}

.ProjectDetails .showDeleted {
  margin-right: 5px;
}

.ProjectDetails .deleteButton {
  margin-left: 15px;
}

.ProjectDetails .formItem {
  padding: 2px 30px 2px 0px;
  margin-bottom: 0px;
}

