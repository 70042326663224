.PasswordResetForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 384px;
}

.PasswordResetForm .formCard {
  padding: 25px;
  background: rgba(216, 216, 216, 0.5);
  border-radius: 8px;
  
}

.PasswordResetForm .resultMessage {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.PasswordResetForm .intro {
  text-align: center;
}

.PasswordResetForm .introTitle {
  font-size: large;
  font-weight: 450;
}

.PasswordResetForm .formInput {
  height: 40px;
}
