/* default button */
.button:global.ant-btn {
    border-radius: 4px;
    padding: var(--padding) 25px;
    max-width: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--fontSize);
    line-height: 1.25;
    height: 32px;
}

.button:global.ant-btn:disabled {
    background: var(--disabled);
    color: var(--white);
}

.buttonBigger:global.ant-btn {
    padding: var(--paddingBigger) 25px;
    font-size: var(--fontSizeBigger);
    height: 40px;
}

.iconOnly:global.ant-btn {
    padding: 0;
    width: 100%;
}

.buttonSizeAuto:global.ant-btn {
    max-width: none;
    width: auto;
    height: auto;
}

/* primary */
.primary:global.ant-btn {
    background: var(--darkBlue);
    color: var(--white);
}

.primary:global.ant-btn:focus,
.primary:global.ant-btn:hover {
    background: rgba(0, 129, 183, 0.9);
    color: var(--white);
}

/* outlined */
.outlined:global.ant-btn {
    background: transparent;
    border: 1px solid var(--darkBlue);
    color: var(--darkBlue);
}

.outlined:global.ant-btn:focus,
.outlined:global.ant-btn:hover {
    border: 1px solid rgba(0, 129, 183, 0.9);
    color: var(--white);
    background: rgba(0, 129, 183, 0.9);
}

.outlined:global.ant-btn:disabled {
    background: inherit;
    border: 1px solid var(--disabled);
    color: var(--disabled);
}

/* success */
.success:global.ant-btn {
    background: var(--green);
    color: var(--white);
}

.success:global.ant-btn:focus,
.success:global.ant-btn:hover {
    background: var(--green);
    color: var(--white);
}

/* negative */
.negative:global.ant-btn {
    background: var(--red);
    color: var(--white);
}

.negative:global.ant-btn:focus,
.negative:global.ant-btn:hover {
    background: rgba(218, 91, 21, 0.9);
    border-color: var(--red);
    color: var(--white);
}