.RecoveryForm {
  height: 100%;
}

.RecoveryForm .formCard {
  width: 384px;
  padding: 25px;
  padding-bottom: 0;
  background: rgba(216, 216, 216, 0.5);
  border-radius: 4px;
}

.RecoveryForm .intro {
  text-align: center;
}

.RecoveryForm .introTitle {
  font-size: large;
  font-weight: 450;
}

.RecoveryForm .formInput {
  height: 40px;
}

.RecoveryForm .buttonColumn {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.RecoveryForm .link {
  text-decoration: underline;
}

.RecoveryForm .contactMessage {
  padding: 30px 10px 50px 10px;
  text-align: center;
}
