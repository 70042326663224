.MachinesFilters {
  margin-bottom: 1rem;
}

.MachinesFilters .input,
.MachinesFilters .searchInput,
.MachinesFilters .installerInput,
.MachinesFilters .unitsRefsInput,
.MachinesFilters .projectTypeInput,
.MachinesFilters .button {
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
}

.MachinesFilters .searchInput {
  width: 251px;
}

.MachinesFilters .installerInput,
.MachinesFilters .unitsRefsInput,
.MachinesFilters .projectTypeInput {
  width: 224px;
}

.MachinesFilters .recordsPerPageInput {
  width: 65px;
  margin-right: 0.5rem;
}
