.Title {
  display: flex;
  justify-content: center;
}

.firstText {
  font-size: 48px;
  font-weight: bold;
  color: #132b76;
}
