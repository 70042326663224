.RoomsConfigTable {
}

.RoomsConfigTable .mainRoomColumn {
  width: 15%;
}

.RoomsConfigTable .roomColumn {
  width: 7.5%;
}

.RoomsConfigTable .channelsUsedInput {
  width: 50px;
  margin-right: 8px;
}

.headerPanel {
  height: 31px;
  border-radius: 4px;
  background-color: #d8d8d8;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel {
  height: 31px;
  width: 214px;
  border-radius: 4px;
  margin: 0 10px;
}

.captionCol {
  margin-top: 38px;
}

.rowName {
  height: 33px;
  margin: 6px 2px;
  display: flex;
  align-items: center;
}

.input {
  margin: 0px 0px;
}

.input .inputNumber {
  width: 100%;
}

.cell {
  width: 100%;
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col:global.ant-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0px;
}

.col:global.ant-col:not(:last-of-type) {
  margin-bottom: 10px;
  margin: 0 5px;
}

.additionalFirstRow {
  margin: 15px 0px 0px 0px;
}

.additionalRow {
  margin: 70px 0px;
}

.dataBlock {
  margin-bottom: 10px;
}
