.NotFoundPage {
  height: 100%;
}

.NotFoundPage .pageRow {
  height: 85%;
}

.NotFoundPage .wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.NotFoundPage .code404 {
  font-size: 200px;
  line-height: 1;
  color: rgba(19, 43, 118, 0.23);
}

.NotFoundPage .message {
  font-size: 24px;
  color: rgba(41, 41, 41, 0.51);
}
