.SiteHeader {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 2.2rem 0.6rem 0;
}

.SiteHeader .logo {
  margin: 20px 0 0 30px;
  width: 250px;
  height: 60px;
}

.SiteHeader .quitButton {
  cursor: pointer;
  margin-top: 30px;
  margin-right: 30px;
  height: 40px;
  min-width: 150px;
  border-radius: 4px;
  padding: 10px;
  background-color: #132b76;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SiteHeader .quitButtonText {
  height: 16px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.SiteHeader .quitIcon {
  width: 18px;
  height: 22px;
  margin-left: 15px;
}

.SiteHeader .bcText {
  font-size: 15px;
}

.SiteHeader .bcLink {
  font-size: 15px;
  color: #1890ff;
}
.SiteHeader .bcLink:hover {
  text-decoration: underline;
}
